label, p {
  font-size: 1.14rem;
}

header {
  background: var(--background-secondary);
  padding: 1.5625rem;

  a {
    color: var(--text);
  }
}

#presets {
  display: grid;
  gap: 4.5rem;
  grid-template-columns: repeat(auto-fit, minmax(16.25rem, 1fr));
}