:root {
  --background: linear-gradient(#0e6d45 -200%, #f7f7f7);
  --background-secondary: #f7f7f7;
  --text-secondary: #e0fff2;
  --text: #36463f;
  --green: #0aac68;
  --dark-green: #0e6d45;
}

[data-theme="dark"] {
  --background: linear-gradient(#0e6d45 -200%, #222);
  --background-secondary: #222;
  --text-secondary: #e0fff2;
  --text: #e0fff2;
  --green: #0aac68;
  --dark-green: #0e6d45;
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  } to {
    transform: scale(1);
  }
}

* {
  box-sizing: border-box;
  font-family: Manrope, sans-serif;
  list-style: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (max-width: 630px) {
  html {
    font-size: .9375rem;
  }
}

@media screen and (max-width: 540px) {
  html {
    font-size: .875rem;
  }
}

#App {
  background: var(--background);
  color: var(--text);
  min-height: 100vh;
}

button {
  align-items: center;
  background: linear-gradient(var(--green), var(--dark-green));
  border: 2px solid var(--dark-green);
  border-radius: 5px;
  box-shadow: inset 0 2px rgba(255, 255, 255, 0.2), inset 0 -2px 5px var(--dark-green);
  color: var(--text-secondary);
  cursor: pointer;
  display: inline-flex;
  font-size: .875rem;
  font-weight: bold;
  justify-content: center;
  padding: .625rem 1.25rem;
  transition: .1s;
  
  svg {
    font-size: 1.375rem;
    margin-right: .5rem;
  }
  
  &:hover {
    transform: scale(1.05);
  }
}

#toggleTheme {
  background: none;
  border: none;
  box-shadow: none;
  margin: 1.5625rem;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;

  svg {
    color: var(--dark-green);
    margin: 0;
  }
}

label, p {
  font-size: 1.14rem;
}

p {
  line-height: 1.7;

  & + p {
    margin-top: 1rem;
  }

  a {
    align-items: center;
    background: var(--green);
    border-radius: 3px;
    color: var(--background-secondary);
    display: inline-flex;
    padding: 0 .3rem;

    & + a {
      margin-left: .5rem;
    }

    svg {
      margin-right: .3rem;
    }
  }
}

header {
  background: var(--background-secondary);
  padding: 1.5625rem;

  a {
    color: var(--text);
  }
}

main {
  padding: 4rem;
  
  h2 {
    align-items: center;
    border-bottom: 1px solid var(--green);
    display: flex;
    margin-bottom: 2rem;
    margin-top: 4rem;
    padding-bottom: 1rem;

    svg {
      margin-right: .9375rem;
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(200%);
  } to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  } to {
    transform: translateY(200%);
  }
}

.alertOldDomain {
  transform: translateY(200%);
  position: fixed;
  bottom: 0;
  z-index: 2;
  background-color: #00000090;
  color: white;
  padding: 2rem;
  width: 100%;
  animation: slideUp 0.5s forwards 1s;
}

.alertOldDomain.hide {
  animation: slideDown 0.5s forwards;
}

.alertOldDomainContainer {
  max-width: 800px;
  margin: 0 auto;
}

label.understood-old-domain {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.alertOldDomainOptions {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.alertOldDomainOptions a {
  background: var(--green);
  border-radius: 3px;
  color: var(--background-secondary);
  display: inline-flex;
  padding: 0.3rem 0.7rem;
  font-size: 1.14rem;
  font-weight: bold;
}

input#understood-old-domain {
  width: 2rem;
  height: 2rem;
}
