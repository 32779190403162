#about {
  h2, p {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }

  p.aboutTheCreator {
    align-items: center;
    display: grid;
    gap: 2rem;
    grid-template-columns: auto 1fr;
    #imgAboutCreator {
      border-radius: 15px;
      display: block;
      margin: 0;
      height: 5rem;
    }

    @media screen and (max-width: 508px) {
      & {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
      }
    }
  }
}
