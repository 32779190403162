#countdown {
  align-content: center;
  background: var(--background);
  display: grid;
  gap: 2.5rem;
  height: 100vh;
  justify-items: center;
  position: absolute;
  width: 100%;

  @media screen and (max-width: 630px) {
    & {
      padding: 0 2rem;
    }
  }

  .timer {
    align-items: center;
    display: flex;
    font-weight: bold;

    @media screen and (max-width: 630px) {
      & {
        margin-top: 3rem;
      }
    }
    
    span {
      background: linear-gradient(125deg, var(--green) 30%, var(--dark-green) 80%);
      color: var(--text-secondary);
      display: inline-block;
      font-size: 7.75rem;
      height: 10.5625rem;
      margin: .1rem;
      text-align: center;
      text-shadow: 0 0 50px rgba(0, 0, 0, 0.7);
      width: 7.625rem;

      @media screen and (max-width: 550px) {
        & {
          font-size: 4rem;
          height: 5.5rem;
          width: 4rem;
        }
      }
    }

    .minutes {
      span {
        &:first-child {
          border-radius: 15px 0 0 15px;
        }
        
        &:last-child {
          border-radius: 0 15px 15px 0;
        }
      }
    }
    
    .seconds {
      span {
        &:first-child {
          border-radius: 15px 0 0 15px;
        }
        
        &:last-child {
          border-radius: 0 15px 15px 0;
        }
      }
    }


    & > span {
      background: none;
      color: var(--dark-green);
      font-size: 7.75rem;
      margin: 0;
      width: auto;
      height: auto;

      @media screen and (max-width: 630px) {
        & {
          font-size: 4rem;
        }
      }
    }
  }

  .customInterval {
    display: flex;
    margin: 0 auto;
    max-width: 33.9375rem;
    width: 100%;

    @media screen and (max-width: 630px) {
      & {
        flex-direction: column;
      }
    }

    input {
      background: var(--background-secondary);
      border: 2px solid var(--dark-green);
      border-right: 0;
      border-radius: 5px 0 0 5px;
      color: var(--text);
      flex: 1;
      font-size: 1.1875rem;
      outline-color: var(--dark-green);
      outline-offset: -1px;
      padding: 0 1.25rem;
      height: 100%;

      @media screen and (max-width: 630px) {
        & {
          border-radius: 5px;
          border: 2px solid var(--dark-green);
          padding: .5rem;
        }
      }

      &:focus ~ button {
        border-radius: 5px;
      }
    }

    button {
      border-radius: 0 5px 5px 0;

      @media screen and (max-width: 630px) {
        & {
          border-radius: 5px;
        }
      }
      
      &:hover {
        border-radius: 5px;
      }
      
      &:disabled {
        cursor: not-allowed;
        filter: saturate(.3);
        opacity: .5;
      }
      
      &:disabled:hover {
        border-radius: 0 5px 5px 0;
        transform: scale(1);
      }
    }
  }

  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      margin: .9375rem;
    }


    @media screen and (max-width: 540px) {
      & {
        flex-direction: column;
      }
    }
  }

  #countdownEndMessage {
    align-content: center;
    animation: scaleIn .4s forwards;
    background: rgba(0, 0, 0, 0.9);
    color: var(--text-secondary);
    display: grid;
    gap: 2.5rem;
    height: 100%;
    justify-items: center;
    position: absolute;
    width: 100%;
    z-index: 3;

    strong {
      font-size: 3.25rem;
      text-align: center;

      .tada {
        font-size: 5.625rem;
        margin-bottom: .625rem;
      }
    }
  }
}